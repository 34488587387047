export default{
  install(Vue){
    Vue.prototype.$erroCdMsg = function(code){
      let msg = "";
      switch (code) 
      {
        case -9001:
          // alert('중복');
          msg = "중복된 아이디 입니다.";
          break;
        case -9002:
          // alert('중복');
          msg = "현재비밀번호가 일치하지 않습니다.";
          break;
    
        default:
          break;
      }
      return msg;
    }
    Vue.prototype.$getCmnCdList = async function(classCode) {
      const formData = new FormData();
      formData.append("cls_cd", classCode);

      try {
        const res = await this.$http.post("/common/getCmnCdListByClsCd", formData);
        const filtered = res.data.result.filter(e => e.cmn_cd != '00');
        return filtered;
      } catch (error) {
        console.log(error);
      }
      return [];
    }
    Vue.prototype.$getSoctyCmnCdList = async function(classCode) {
      const formData = new FormData();
      formData.append("cls_cd", classCode);

      try {
        const res = await this.$http.post("/common/getSoctyCmnCdListByClsCd", formData);
        const filtered = res.data.result.filter(e => e.cmn_cd != '00');
        return filtered;
      } catch (error) {
        console.log(error);
      }
      return [];
    }
    Vue.prototype.$isIncludeHangle = function(value) {
			const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      return regex.test(value);
		}

		Vue.prototype.$isIncludeAlphaChar = function(value) {
			const regex = /[a-zA-Z]/;
      return regex.test(value);
		}

		Vue.prototype.$isIdValid = function(value) {
			const regex = /^[A-Za-z0-9]*$/;
      return regex.test(value);
		}

		Vue.prototype.$isEmailValid = function(value) {
			const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      return regex.test(value);      
		}

    Vue.prototype.$isPasswordValid = function(value) {
      // 최소 8자, 영문자, 숫자, 특수문자 포함
			const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      return regex.test(value);      
		}

    Vue.prototype.$setDateCalendarFormat = function(str) {
      return str.substring(0, 4) + "-" + str.substring(4, 6) + "-" + str.substring(6);
    }
  }
}
