<template>
  <v-container class="pa-3">
    <v-row dense class="">
      <v-col cols="12" class="">
        <v-card flat outlined height="40vh" class="pa-3">
          <v-row dense>
            <v-col cols="6">
              <div class="pt-1">
                <h4>
                  최근 심전도 검사
                </h4>
              </div>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="indigo lighten-1" class="white--text" @click="$router.push({name:'ecgTestList'})">더보기</v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="ecgTestHeaders"
                :items="ecgTestList"
                hide-default-footer
                >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" class="">
        <v-card flat outlined height="45vh" class="pa-3">
          <v-row dense>
            <v-col cols="6">
              <div>
                <h4>
                  최근 등록 사용자
                </h4>
              </div>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="indigo lighten-1" class="white--text" @click="$router.push({name:'userList'})">더보기</v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="userHeaders"
                :items="userList"
                hide-default-footer
                >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" class="">
        <v-card flat outlined height="45vh" class="pa-3">
          <v-row dense>
            <v-col cols="6">
              <div>
                <h4>
                  최근 등록 장치
                </h4>
              </div>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="indigo lighten-1" class="white--text" @click="$router.push({name:'ecgDeviceList'})">더보기</v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="ecgDvcHeaders"
                :items="ecgDvcList"
                hide-default-footer
                >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data(){
    return{
      ecgTestHeaders: [
        // { text: 'No.', value:'no'},
        { text: '사용자 이름', value:'user_nm'},
        { text: '사용자 전화번호', value:'user_cel_no'},
        { text: '심전도 장치명', value:'ecg_dvc_nm'},
        { text: '패치 종류', value:'test_patch_kind'},
        { text: '검사 시작일시', value:'test_bgn_dtm'},
        { text: '검사 종료일시', value:'test_end_dtm'},
        { text: '검사 결과', value:'test_res'},
        { text: '등록일시', value:'reg_dtm'},
      ],
      ecgTestList: [],

      userHeaders: [
        // { text: 'No.', value:'no'},
        { text: '사용자 이름', value:'user_nm'},
        // { text: '사용자 전화번호', value:'user_cel_no'},
        // { text: '긴급 연락처', value:'emgnc_cntt1_tel_no'},
        { text: '사용 심전도 장치', value:'ecg_dvc_nm'},
        { text: '등록일시', value:'reg_dtm'},
      ],
      userList: [],

      ecgDvcHeaders: [
        // { text: 'No.', value:'no'},
        { text: '심전도 장치 종류', value:'ecg_dvc-kind'},
        { text: '심전도 장치명', value:'ecg_dvc_nm'},
        // { text: '심전도 장치ID', value:'ecg_dvc_id'},
        { text: '등록일시', value:'reg_dtm'},
      ],
      ecgDvcList: [],

    }
  },
  created() {
    // console.log("$cookies.admorSno:", this.$cookies.get("admorSno"));
    // if(this.$cookies.get("admorSno") == null) {
    //   this.$router.push({name:'login'});
    // }
  },
  mounted() {
    this.getEcgTestList();
    this.getUserList();
    this.getEcgDvcList();
  },
  methods: {
    getEcgTestList() {
      const params = new FormData();

      params.append("limit", "5");

      this.$http.post("/api/admin/getEcgTestList", params).then((res) => {
        // alert(res.data.result);
        console.log("getEcgTestList > res.data:", res.data);
        this.ecgTestList = res.data.ecgTestList;
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    getUserList() {
      const params = new FormData();

      params.append("limit", "5");

      this.$http.post("/api/admin/getUserList", params).then((res) => {
        // alert(res.data.result);
        console.log("getUserList > res.data:", res.data);
        this.userList = res.data.userList;
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    getEcgDvcList() {
      const params = new FormData();

      params.append("limit", "5");

      this.$http.post("/api/admin/getEcgDvcList", params).then((res) => {
        // alert(res.data.result);
        console.log("getEcgDvcList > res.data:", res.data);
        this.ecgDvcList = res.data.ecgDvcList;
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
  }
}
</script>

<style>

</style>