<template>
  <v-container class="pa-3">
    <v-row dense class="">
      <v-col cols="12" class="">
        <v-card flat outlined height="85vh" class="pa-3">
          <v-row dense>
            <v-col cols="12">
              <div class="pt-1">
                <h4>
                  접속 이력
                </h4>
              </div>
            </v-col>
            <v-col cols="12" class="">
              <v-row dense align="center">
                <v-col cols="4" class="d-flex">
                  <datePicker p_dateName="srchBgnDt" ref="srchBgnDt"></datePicker>
                  <span class="ml-2 mr-2 pt-2">~</span>
                  <datePicker p_dateName="srchEndDt" ref="srchEndDt"></datePicker>
                </v-col>
                <v-col cols="3" class="d-flex align-center">
                  <v-text-field 
                    outlined 
                    hide-details
                    dense 
                    height="25"
                    class="mr-2"
                    v-model="search"
                    placeholder="검색어를 입력하세요."
                    :append-icon="search != null && search != '' ? 'mdi-close-circle' : ''"
                    @click:append="clearSearch"
                    >
                  </v-text-field>
                  <v-btn color="indigo lighten-1" class="white--text" @click="srchBtnClicked">
                    조회
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="gridHeaders"
                :items="gridList"
                :search="search"
                hide-default-footer
                :items-per-page="itemsPerPage"
                :page.sync="page"
                @page-count="pageCount = $event"
                :loading="loading"
                loading-text="Loading... Please wait"
                >
                <template v-slot:[`item.no`]="{index}" >
                  {{ (itemsPerPage * (page - 1)) + index + 1}}
                </template>
                <template v-slot:no-results>
                  <span>검색 결과가 없습니다.</span>
                </template>
              </v-data-table>
              <div class="text-center pt-2 hidden-md-and-down">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datePicker from '@/component/datePicker.vue';
export default {
  components: {datePicker},
  data(){
    return{
      gridHeaders: [
        { text: 'No.', value:'no'},
        { text: '일자', value:'login_dt'},
        { text: '시간', value:'login_tm'},
        { text: 'IP', value:'ip_addr'},
        { text: '로그인ID', value:'login_id'},
        { text: '이름', value:'admor_nm'},
      ],
      gridList: [],
      search: null,
      srchBgnDt: null,
      srchEndDt: null,
      // today: null,

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      loading: false,
    }
  },
  created() {
    this.srchEndDt = new Date();
    this.srchBgnDt = new Date();
    this.srchBgnDt.setMonth(this.srchEndDt.getMonth() - 1);
  },
  mounted() {
    this.$refs.srchBgnDt.date = new Date(this.srchBgnDt.getTime() - (this.srchBgnDt.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
    this.$refs.srchEndDt.date = new Date(this.srchEndDt.getTime() - (this.srchEndDt.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

    this.getLoginHist();
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
    getLoginHist() {
      const params = new FormData();

      params.append("srch_bgn_dt", this.$refs.srchBgnDt.date);
      params.append("srch_end_dt", this.$refs.srchEndDt.date);

      this.$http.post("/api/admin/getLoginHist", params).then((res) => {
        // alert(res.data.result);
        console.log("getLoginHist > res.data:", res.data);
        this.gridList = res.data.loginHist;
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    srchBtnClicked() {
      this.getLoginHist();
    }
  }
}
</script>

<style>

</style>