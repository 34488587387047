<template>
  <v-container fluid v-if="$route.name !== 'mMain' && $route.name !== 'account' && $route.name !== 'login'">
    <v-app-bar 
      app 
      clipped-left
      class="elevation-0" 
      style="border-bottom: 0.1rem solid #2E64FE; background-color: white;" 
      v-if="$route.name !== 'mMain' && $route.name !== 'account'"
      >
      <v-row>
        <v-col cols="4">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <span>심전도 매트리스 관리자 사이트</span>
        </v-col>
        <v-col cols="6">
          <div class="pr-10" style="width:100%;">
            <!-- <v-toolbar-title align="center" class="justify-center d-flex">
              {{this.$route.name}}
            </v-toolbar-title> -->
            <v-menu
              v-for="(item, idx) in menuItems"
              :key="idx"
            >
              <template v-slot:activator="{on}">
                <v-btn
                  text
                  v-on="on"
                  :to="item.to"
                >{{ item.text }}
                </v-btn>
              </template>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="login"
                  v-show="!$store.getters.getAdmorIsLoggedIn"
                  >
                  <v-icon>mdi-login</v-icon>
                </v-btn>
              </template>
              <span>로그인</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="logout"
                  v-show="$store.getters.getAdmorIsLoggedIn"
                  >
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>로그아웃</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn 
                  icon 
                  v-on="on" 
                  @click="setting"
                  >
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <span>설정</span>
            </v-tooltip> -->
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
    
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      >
      <!-- <v-list-item nav>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            ECG
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider color="#2E64FE"></v-divider> -->
      
      <v-list
        nav
        dense>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          link
          :to="item.to"
          >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  
  </v-container>
</template>

<script>
//import drawer from "./drawer"
export default {
  // components:[
  //   drawer
  // ],
  data(){
    return {
      menu:[],
      drawer: false,
      perm_lvl: null,
    }
  },
  computed: {
    // drawer: {
    //   get () {
    //     return this.$store.getters['app/getDrawer']
    //   },
    //   set (value) {
    //     return this.$store.commit('app/setDrawer', value)
    //   }
    // }
    menuItems() {
      const items = [
        {icon:'mdi-clipboard-pulse-outline', text:'현황', to:'adminMain'},
        {icon:'mdi-file-document-outline', text:'심전도 검사 기록', to:'ecgTestList'},
        {icon:'mdi-account', text:'사용자 정보', to:'userList'},
        // {icon:'mdi-heart-pulse', text:'심전도 장치 정보', to:'ecgDeviceList'},
      ];

      if(this.$store.getters.getAdmorPermLvl == 'super') {
        items.push(
          {icon:'mdi-account-badge', text:'관리자 현황', to:'admorList'},
          {icon:'mdi-history', text:'접속 이력', to:'loginHistory'},
        );
      }

      return items;
    }
  },
  created() {
    // console.log("appBar created");
  },
  mounted() {
    // console.log("appBar mounted");
  },
  methods: {
    login() {
      this.$router.push({name:'login'}).catch(() => {});
    },
    logout() {
      this.$store.commit('setAdmorLoggedIn', null);
      this.$router.push({ name:'login'}).catch(() => {});
    },
    setting() {
      console.log("setting");
    }
  },
}
</script>

<style>

</style>