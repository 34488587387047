<template>
  <v-container class="pa-3">
    <v-row dense class="">
      <v-col cols="12" class="">
        <v-card flat outlined height="85vh" class="pa-3">
          <v-row dense>
            <v-col cols="12">
              <div class="pt-1">
                <h4>
                  심전도 정보
                </h4>
              </div>
            </v-col>
            <v-col cols="12" class="">
              <v-row dense align="center">
                <v-col cols="4" class="d-flex">
                  <datePicker p_dateName="srchBgnDt" ref="srchBgnDt"></datePicker>
                  <span class="ml-2 mr-2 pt-2">~</span>
                  <datePicker p_dateName="srchEndDt" ref="srchEndDt"></datePicker>
                </v-col>
                <v-col cols="3" class="d-flex align-center">
                  <v-text-field 
                    outlined 
                    hide-details
                    dense 
                    height="25"
                    class="mr-2"
                    v-model="search"
                    placeholder="검색어를 입력하세요."
                    :append-icon="search != null && search != '' ? 'mdi-close-circle' : ''"
                    @click:append="clearSearch"
                    >
                  </v-text-field>
                  <v-btn color="indigo lighten-1" class="white--text" @click="srchBtnClicked">
                    조회
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="gridHeaders"
                :items="gridList"
                :search="search"
                hide-default-footer
                :items-per-page="itemsPerPage"
                :page.sync="page"
                @page-count="pageCount = $event"
                :loading="loading"
                loading-text="Loading... Please wait"
                >
                <template v-slot:[`item.no`]="{index}" >
                  {{ (itemsPerPage * (page - 1)) + index + 1}}
                </template>

                <template v-slot:[`item.download`]="{item}">
                  <v-btn @click="downBtnClicked(item)">
                    <v-icon >mdi-download</v-icon>
                  </v-btn>
                </template>

                <template v-slot:no-results>
                  <span>검색 결과가 없습니다.</span>
                </template>
                
              </v-data-table>
              <div class="text-center pt-2 hidden-md-and-down">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datePicker from '@/component/datePicker.vue';
export default {
  components: {datePicker},
  data(){
    return{
      gridHeaders: [
        { text: 'No.', value:'no'},
        { text: '사용자 이름', value:'user_nm'},
        { text: '사용자 전화번호', value:'user_cel_no'},
        { text: '심전도 장치명', value:'ecg_dvc_nm'},
        { text: '패치 종류', value:'test_patch_kind'},
        { text: '검사 시작일시', value:'test_bgn_dtm'},
        { text: '검사 종료일시', value:'test_end_dtm'},
        { text: '검사 결과', value:'test_res'},
        { text: '등록일시', value:'reg_dtm'},
        { text: '다운로드', value:'download'},
      ],
      gridList: [],
      search: null,
      srchBgnDt: null,
      srchEndDt: null,
      // today: null,

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      loading: false,
    }
  },
  created() {
    this.srchEndDt = new Date();
    this.srchBgnDt = new Date();
    this.srchBgnDt.setMonth(this.srchEndDt.getMonth() - 1);
  },
  mounted() {
    this.$refs.srchBgnDt.date = new Date(this.srchBgnDt.getTime() - (this.srchBgnDt.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
    this.$refs.srchEndDt.date = new Date(this.srchEndDt.getTime() - (this.srchEndDt.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

    this.getEcgTestList();
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
    getEcgTestList() {
      const params = new FormData();

      params.append("srch_bgn_dt", this.$refs.srchBgnDt.date);
      params.append("srch_end_dt", this.$refs.srchEndDt.date);

      this.$http.post("/api/admin/getEcgTestList", params).then((res) => {
        // alert(res.data.result);
        console.log("getEcgTestList > res.data:", res.data);
        this.gridList = res.data.ecgTestList;
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    srchBtnClicked() {
      this.getEcgTestList();
    },
    downBtnClicked(item) {
      // console.log("item :: ",item);
      const params = new FormData();
      params.append("file_atch_sno", item.file_atch_sno);

      this.$http.post("/api/common/getFileAtch",params).then((res) => {
        console.log(res);
        //원본
        let fileList = res.data.fileList;
        if(fileList) {
          fileList.forEach((e) => {
            // e.file_path
            if(e.orgnl_file_nm == 'record.csv') {
              const filePath = e.file_path;

              this.downloadFile(filePath, blob => {
                  // blobs.push(blob);
                  this.downloadBlobs(blob);
              });
            }
            
          })
        }
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },//downBtnClicked end
    downloadFile(url, callback) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => callback(blob))
        .catch(error => console.error('File download failed:', error));
    },//downloadFile end
    downloadBlobs(blob) {
        // const mergedBlob = new Blob(blobs, { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        const date = this.getCurrentDateTime();
        let fileNm = 'ecg_data_'+date+'.csv';
        downloadLink.download = fileNm; // 다운로드될 파일명 설정
        downloadLink.click();
    },//mergeBlobs end
    getCurrentDateTime() {
      var now = new Date();
      
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hours = now.getHours();
      var minutes = now.getMinutes();
      var seconds = now.getSeconds();

      // 월, 일, 시, 분, 초가 한 자리 수인 경우 앞에 0을 추가
      if (month < 10) {
          month = '0' + month;
      }
      if (day < 10) {
          day = '0' + day;
      }
      if (hours < 10) {
          hours = '0' + hours;
      }
      if (minutes < 10) {
          minutes = '0' + minutes;
      }
      if (seconds < 10) {
          seconds = '0' + seconds;
      }

      var dateTime = year + month + day + hours + minutes + seconds;
      return dateTime;
    },//getCurrentDateTime end

  }
}

</script>

<style>

</style>