<template>
  <v-app>
    <app-bar/>
    <app-view/>
    <app-footer/>
  </v-app>
</template>

<script>
import appBar from './layouts/appBar.vue';
import AppFooter from './layouts/appFooter.vue';
import AppView from './layouts/appView.vue';

export default {
  components: { appBar, AppView, AppFooter },
  name: 'App',

  mounted() {
    // this.$store.dispatch('checkLogin');
  }
};
</script>

<style>
@import "./styles/common.scss";

</style>
