<template>
  <v-container class="" fluid style="height:50vh;">
    <v-layout fill-height class="">
      <v-row class="ma-0 mt-10 pa-3 pt-10" align="center" style="">
        <v-col cols="12" xs="12" class="justify-center d-flex">
          <div class="" style="text-align: center;">
            <div class="pb-10">
              <span class="">ECG logo</span>
            </div>
            <div>
              <span class="" style="text-align: center;"><h1>심전도 매트리스<br>관리자 사이트</h1></span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class=" justify-center d-flex">
          <div class=" justify-center d-flex" style="width:70%">
            <div style="align-items: center; width:30vh;" class="pa-3 theme-blue-bg1">
              <div class="justify-center d-flex mb-3">
                <span><h3>로그인</h3></span>
              </div>
              <v-text-field 
                hide-details
                outlined
                dense
                background-color="white"
                height="25"
                class="mb-3"
                label="아이디"
                style="width:100%;"
                v-model="loginId"
                ></v-text-field>
              <v-text-field 
                hide-details
                outlined
                dense
                background-color="white"
                height="25"
                class="mb-3"
                type="password"
                label="비밀번호"
                style="width:100%;"
                v-model="pwd"
                @keyup.enter="loginClicked"
              ></v-text-field>
              <v-btn 
                block 
                tile
                color="indigo light-2"
                class="white--text mb-3"
                @click="loginClicked"
                >로그인
              </v-btn>
              <v-checkbox
                hide-details
                label="아이디 저장"
                v-model="isSaveId"
              > 
              </v-checkbox>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isSaveId: false,
      loginId: null,
      pwd: null,
    }
  },
  created() {
    // console.log("login created.");
    this.loginId = this.$store.state.saveLoginId == null ? null : this.$store.state.saveLoginId.loginId;
    this.isSaveId = this.$store.state.saveLoginId == null ? false : this.$store.state.saveLoginId.isSaveId;
  },
  mounted() {
    // console.log("login mounted.");
  },
  methods: {
    saveId() {
      // console.log("saveId:", this.isSaveId, typeof(this.isSaveId), this.loginId);
      this.$store.commit('setSaveLoginId', {isSaveId: this.isSaveId, loginId: this.isSaveId ? this.loginId : null});
    },
    validateInput() {
      // 로그인ID
      if(this.loginId == null || this.loginId == "") {
        alert("아이디 입력이 필요합니다.")
        return false;
      }
      // 비밀번호
      if(this.pwd == null || this.pwd == "") {
        alert("비밀번호 입력이 필요합니다.")
        return false;
      }

      return true;
    },
    loginClicked() {
      const isValid = this.validateInput();
      if(!isValid) {
        return;
      }
      this.saveId();

      const params = new FormData();

      params.append("login_id", this.loginId);
      params.append("pwd", this.pwd);

      this.$http.post("/api/admin/login", params).then((res) => {
        console.log("login > res.data:", res.data);

        if(res.data.code == 0) {
          this.$store.commit('setAdmorLoggedIn', {isLoggedIn: true, admorSno: res.data.user.admor_sno, permLvl: res.data.user.perm_lvl});
          this.$router.push({name:"adminMain"});
        } else {
          alert("로그인에 실패했습니다.");
        }
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    }
  }
}
</script>

<style>

</style>