<template>
  <v-container class="pa-3">
    <v-row dense class="">
      <v-col cols="12" class="">
        <v-card flat outlined height="85vh" class="pa-3">
          <v-row dense>
            <v-col cols="12">
              <div class="pt-1">
                <h4>
                  관리자 현황
                </h4>
              </div>
            </v-col>
            <v-col cols="12" class="">
              <v-row dense align="center">
                <v-col cols="3" class="d-flex align-center">
                  <v-text-field 
                    outlined 
                    hide-details
                    dense 
                    height="25"
                    class="mr-2"
                    v-model="search"
                    placeholder="검색어를 입력하세요."
                    :append-icon="search != null && search != '' ? 'mdi-close-circle' : ''"
                    @click:append="clearSearch"
                    >
                  </v-text-field>
                  <!-- <v-btn color="indigo lighten-1" class="white--text">
                    조회
                  </v-btn> -->
                </v-col>
                <v-col cols="8"></v-col>
                <v-col cols="1" class="d-flex justify-end align-center">
                  <v-btn color="black lighten-1" class="white--text" @click="addAdmorClicked">
                    추가
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="gridHeaders"
                :items="gridList"
                :search="search"
                hide-default-footer
                :items-per-page="itemsPerPage"
                :page.sync="page"
                @page-count="pageCount = $event"
                :loading="loading"
                loading-text="Loading... Please wait"
                @dblclick:row="admorDblclicked"
                >
                <template v-slot:[`item.no`]="{index}" >
                  {{ (itemsPerPage * (page - 1)) + index + 1}}
                </template>
                <template v-slot:no-results>
                  <span>검색 결과가 없습니다.</span>
                </template>
                <template v-slot:[`item.delete`]="{item}" >
                  <v-btn 
                    v-model="item.delete"
                    small
                    @click="deleteAdmorClicked(item)"
                    v-if="item.perm_lvl != 'super'"
                    >삭제
                  </v-btn>
                </template>
              </v-data-table>
              <div class="text-center pt-2 hidden-md-and-down">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <edit-admor-popup v-if="showEditAdmorPopup" :p_title="editAdmorPopupTitle" :p_tgtAdmorSno="selectedAdmorSno" @close="closeEditAdmorPopup" @registered="admorRegistered"></edit-admor-popup>
    <confirm-popup v-if="showDeleteConfirmPopup" :p_title="confirmPopupTitle" :p_msg="confirmPopupMsg" @close="showDeleteConfirmPopup = false" @confirmed="deleteConfirmed"></confirm-popup>
  </v-container>
</template>

<script>
import editAdmorPopup from '@/component/editAdmorPopup.vue';
import ConfirmPopup from '@/component/confirmPopup.vue';
export default {
  components: { editAdmorPopup, ConfirmPopup },
  data(){
    return{
      gridHeaders: [
        { text: 'No.', value:'no'},
        { text: '로그인 ID', value:'login_id'},
        { text: '관리자 이름', value:'admor_nm'},
        { text: '최근 로그인일시', value:'last_login_dtm'},
        { text: '삭제', value:'delete'},
      ],
      gridList: [],
      search: null,

      admor_sno: null,

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      loading: false,

      editAdmorPopupTitle: "관리자 추가",
      showEditAdmorPopup: false,
      selectedAdmorSno: null,

      showDeleteConfirmPopup: false,
      confirmPopupTitle: "",
      confirmPopupMsg: "",
    }
  },
  created() {
    this.admor_sno = this.$store.getters.getAdmorSno;
    // console.log("admorList created:", this.admor_sno);
  },
  mounted() {
    this.getAdmorList();
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
    getAdmorList() {
      const params = new FormData();

      this.$http.post("/api/admin/getAdmorList", params).then((res) => {
        // alert(res.data.result);
        console.log("getAdmorList > res.data:", res.data);
        this.gridList = res.data.admorList;
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    addAdmorClicked() {
      this.editAdmorPopupTitle = "관리자 정보 추가";
      this.selectedAdmorSno = null;
      this.showEditAdmorPopup = true;
    },
    closeEditAdmorPopup() {
      this.showEditAdmorPopup = false;
    },
    admorRegistered() {
      this.showEditAdmorPopup = false;
      this.getAdmorList();
    },
    admorDblclicked(event, row) {
      console.log("admorDblclicked", row, row.item.admor_sno);
      this.editAdmorPopupTitle = "관리자 정보 수정";
      this.selectedAdmorSno = row.item.admor_sno;
      this.showEditAdmorPopup = true;
    },
    deleteAdmorClicked(item) {
      console.log("deleteAdmorClicked", item);
      this.selectedAdmorSno = item.admor_sno;
      this.confirmPopupTitle = "관리자 삭제 확인";
      this.confirmPopupMsg = "관리자를 삭제 하시겠습니까?";
      this.showDeleteConfirmPopup = true;
    },
    deleteConfirmed() {
      this.showDeleteConfirmPopup = false;

      const params = new FormData();

      params.append("actn_admor_sno", this.admor_sno);
      params.append("admor_sno", this.selectedAdmorSno);

      this.$http.post("/api/admin/deleteAdmor", params).then((res) => {
        // alert(res.data.result);
        console.log("deleteAdmor > res.data:", res.data);
        alert("삭제되었습니다.");
        this.getAdmorList();
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    }
  }
}
</script>

<style>

</style>