import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import store from './store/store'
import VueCookies from "vue-cookies";
import common from '@/common/common.js';

Vue.use(common);
Vue.use(VueCookies);
// 쿠키 만료일
// Vue.$cookies.config("7d");


if (document.location.hostname == 'localhost') {
  axios.defaults.baseURL = 'http://localhost:9082';
} else {
  axios.defaults.baseURL = document.location.origin;
}

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

Vue.prototype.$http = axios;
Vue.config.productionTip = false



new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App),
  // beforeCreate() {
  //   console.log("main.js beforeCreate");
  //   this.$store.dispatch('checkLogin')
  // }
}).$mount('#app')
