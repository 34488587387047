<template>
  <v-dialog v-model="showDialog" width="350px" class="" persistent>
    <v-card>
      <v-app-bar dark color="primary" max-height="70px">
        <v-toolbar-title>{{popupTitle}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-spacer></v-spacer>
      <div style="text-align:center;" class="mt-5 ml-5 mr-5 pb-5">
        <div align="right" style="color:red;" v-if="isNew">* 필수항목</div>
        <v-row>
          <v-col cols="6" align="left"><div class=""><span class="">아이디</span><span style="color:red;" v-if="isNew"> *</span></div></v-col>
          <v-col cols="6">
            <div class="d-flex">
              <v-text-field
                outlined 
                hide-details
                dense 
                height="25"
                class="mr-2"
                v-model="login_id"
                :disabled="!isNew"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right"><span style="color:red;">{{ login_id_msg }}</span></v-col>
        </v-row>
        <v-row>
          <v-col cols="6" align="left"><div class=""><span class="">이름</span></div></v-col>
          <v-col cols="6">
            <div class="d-flex">
              <v-text-field
                outlined 
                hide-details
                dense 
                height="25"
                class="mr-2"
                v-model="admor_nm"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right"><span style="color:red;">{{ admor_nm_msg }}</span></v-col>
        </v-row>
        <v-row>
          <v-col cols="6" align="left"><div class=""><span class="">비밀번호</span><span v-if="isNew" style="color:red;"> *</span></div></v-col>
          <v-col cols="6">
            <div class="d-flex">
              <v-text-field
                outlined 
                hide-details
                dense 
                height="25"
                class="mr-2"
                v-model="pwd"
                type="password"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right"><span style="color:red;">{{ pwd_msg }}</span></v-col>
        </v-row>
        <v-row>
          <v-col cols="6" align="left"><div class=""><span class="">비밀번호 확인</span><span v-if="isNew" style="color:red;"> *</span></div></v-col>
          <v-col cols="6">
            <div class="d-flex">
              <v-text-field
                outlined 
                hide-details
                dense 
                height="25"
                class="mr-2"
                v-model="pwd_chk"
                type="password"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right"><span style="color:red;">{{ pwd_chk_msg }}</span></v-col>
        </v-row>
        <div align="left">[비밀번호 규칙]</div>
        <div align="left">* 최소 8자리 이상이 되어야 합니다.</div>
        <div align="left">* 영문자, 숫자, 특수문자를 포함해야 합니다.</div>

        <v-btn depressed small class="mr-5" @click="$emit('close')">취소</v-btn>
        <v-btn depressed small class="" @click="registBtnClicked">{{saveBtnName}}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['p_title', 'p_tgtAdmorSno'],
  data() {
    return {
      showDialog: true,
      popupTitle: this.p_title,
      isNew: false,
      saveBtnName: "저장",

      actn_admor_sno: null,    // 로그인 한 관리자
      tgt_admor_sno: this.p_tgtAdmorSno,  // 처리 대상 관리자
      login_id: null,
      admor_nm: null,
      pwd: null,
      pwd_chk: null,

      login_id_msg: null,
      admor_nm_msg: null,
      pwd_msg: null,
      pwd_chk_msg: null,
    }
  },
  created() {
    this.actn_admor_sno = this.$store.getters.getAdmorSno;
    console.log("editAdmorPopup created", this.tgt_admor_sno, this.actn_admor_sno);

    if(this.tgt_admor_sno != null) {
      this.getAdmor();
    } else {
      this.isNew = true;
      this.saveBtnName = "등록";
      this.initAdmorData();
    }
  },
  mounted() {

  },
  methods: {
    initAdmorData() {
      this.login_id = null;
      this.admor_nm = null;
      this.pwd = null;
      this.pwd_chk = null;
    },
    initValidateMsg() {
      this.login_id_msg = null;
      this.admor_nm_msg = null;
      this.pwd_msg = null;
      this.pwd_chk_msg = null;
    },
    validateInput() {
      this.initValidateMsg();

      let isValid = true;

      // 로그인ID
      if(this.login_id == null || this.login_id == "") {
        this.login_id_msg = "필수 항목입니다.";
        isValid = false;
      }
      if(!this.$isIdValid(this.login_id)) {
        this.login_id_msg = "영문자, 숫자만 사용 가능합니다.";
        isValid = false;
      }
      // 비밀번호
      if(this.isNew && (this.pwd == null || this.pwd == "")) {
        this.pwd_msg = "필수 항목입니다.";
        isValid = false;
      }
      if(this.pwd != null && this.pwd != "") {
        if(!this.$isPasswordValid(this.pwd)) {
          this.pwd_msg = "비밀번호 규칙에 맞지 않습니다.";
          isValid = false;
        }
      }
      // 비밀번호 확인
      if(this.isNew && (this.pwd_chk == null || this.pwd_chk == "")) {
        this.pwd_chk_msg = "필수 항목입니다.";
        isValid = false;
      }
      if(this.pwd_chk != null && this.pwd_chk != "") {
        if(!this.$isPasswordValid(this.pwd_chk)) {
          this.pwd_chk_msg = "비밀번호 규칙에 맞지 않습니다.";
          isValid = false;
        }
      }
      // 비밀번호, 비밀번호 확인 하나만 입력된 경우
      if(!this.isNew) {
        if((this.pwd != null && this.pwd != "" && this.$isPasswordValid(this.pwd)) && (this.pwd_chk == null || this.pwd_chk == "")) {
          this.pwd_chk_msg = "비밀번호 확인 입력이 필요합니다.";
          isValid = false;
        }
        if((this.pwd == null || this.pwd == "") && (this.pwd_chk != null && this.pwd_chk != "" && this.$isPasswordValid(this.pwd_chk))) {
          this.pwd_msg = "비밀번호 입력이 필요합니다.";
          isValid = false;
        }
      }

      // 비밀번호 일치
      if((this.pwd != null && this.pwd != "" && this.$isPasswordValid(this.pwd)) && (this.pwd_chk != null && this.pwd_chk != "" && this.$isPasswordValid(this.pwd_chk))) {
        if(this.pwd != this.pwd_chk) {
          this.pwd_chk_msg = "비밀번호가 일치하지 않습니다.";
          isValid = false;
        }
      }

      return isValid;
    },
    getAdmor() {
      const params = new FormData();

      params.append("admor_sno", this.tgt_admor_sno);

      this.$http.post("/api/admin/getAdmor", params).then((res) => {
        // alert(res.data.result);
        console.log("getAdmor > res.data:", res.data);

        this.login_id = res.data.admor.login_id;
        this.admor_nm = res.data.admor.admor_nm;
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },
    registBtnClicked() {
      const isValid = this.validateInput();

      if(isValid) {
        const params = new FormData();

        params.append("actn_admor_sno", this.actn_admor_sno);

        params.append("login_id", this.login_id);
        if(this.admor_nm != null)  params.append("admor_nm", this.admor_nm);
        if(this.pwd != null)  params.append("pwd", this.pwd);

        let apiName = "";
        if(this.isNew) {
          apiName = "/api/admin/insertAdmor";
        } else {
          params.append("admor_sno", this.tgt_admor_sno);
          apiName = "/api/admin/updateAdmor";
        }

        this.$http.post(apiName, params).then((res) => {
          // alert(res.data.result);
          console.log(apiName + " > res.data:", res.data);
          alert(this.saveBtnName + " 되었습니다.");
          this.$emit('registered');
        })
        .catch(function (error) {
            console.log(error);
            alert(this.saveBtnName + "에 실패했습니다.");
          }
        );
      }
    }
  }
}
</script>

<style>

</style>