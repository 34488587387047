<template>
  <v-dialog
    ref="dialog"
    v-model="dateModal"
    :return-value.sync="date"
    persistent
    width="290px"
    @keydown.esc="dateModal = false"
    @click:outside="dateModal = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        append-icon="mdi-calendar"
        @click:append="dateModal = true"
        v-bind="attrs"
        v-on="on"
        hide-details
        class="pt-1"
        dense
        outlined
        :background-color="bgColor"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable @dblclick:date="setSelectedDate(date)">
      <v-spacer></v-spacer>
      <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="dateModal = false">취소</v-btn>
      <v-btn small color="indigo darken-3" class="white--text" width="70" @click="setSelectedDate(date)">선택</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>

export default {
  name: "datePicker",
  props: ["p_dateName", "p_bgColor"],

  data() {
    return {
      date: "",
      dateModal: false,
      dateName: this.p_dateName,
      bgColor: this.p_bgColor == null ? "white" : this.p_bgColor,
    };
  },
  methods: {
    setSelectedDate(date) {
      this.$refs.dialog.save(date);
      this.dateModal = false;
      this.$emit('selected', date);
    }
  }
}
</script>