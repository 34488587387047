<template>
  <v-dialog v-model="showDialog"  max-width="300px" class="" persistent>
    <v-card>
      <v-app-bar dark color="primary" max-height="70px">
        <v-toolbar-title>{{ popupTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-spacer></v-spacer>
      <div style="text-align:center;" class="mt-5 pb-5">
        <v-card-text class="">
          {{ popupMsg }}
        </v-card-text>
        <v-btn depressed small class="mr-5" @click="$emit('close')">취소</v-btn>
        <v-btn depressed small class="" @click="$emit('confirmed')">확인</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['p_title', 'p_msg'],
  data() {
    return {
      showDialog: true,
      popupTitle: this.p_title,
      popupMsg: this.p_msg,

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }

}
</script>

<style>

</style>