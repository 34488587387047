<template>
  <v-footer v-if="$route.name !== 'mMain'" app height="30" class="align-center justify-center theme-blue-bg1 hidden-sm-and-down">Copyright 2023. 회사명 Inc. All rights reserved.</v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>