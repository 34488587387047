import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
Vue.use(Router)
Vue.use(store)

import test from "@/view/test"
import privacyPolicy from "@/view/privacyPolicy"

import login from "@/view/admin/login"
import adminMain from "@/view/admin/adminMain"
import ecgTestList from "@/view/admin/ecgTestList"
import userList from "@/view/admin/userList"
import ecgDeviceList from "@/view/admin/ecgDeviceList"
import admorList from "@/view/admin/admorList"
import loginHistory from "@/view/admin/loginHistory"

const router = new Router({
  mode : 'history',
  routes: [
    {
      path: "/test",
      name: "test",
      component: test,
    },
    {
      path: "/privacyPolicy",
      name: "privacyPolicy",
      component: privacyPolicy,
    },
    {
      path: "/login",
      name: "login",
      component: login,
    },
    {
      path: "/adminMain",
      alias: ["/"],
      name: "adminMain",
      component: adminMain,
    },
    {
      path: "/ecgTestList",
      name: "ecgTestList",
      component: ecgTestList,
    },
    {
      path: "/userList",
      name: "userList",
      component: userList,
    },
    {
      path: "/ecgDeviceList",
      name: "ecgDeviceList",
      component: ecgDeviceList,
    },
    {
      path: "/admorList",
      name: "admorList",
      component: admorList,
    },
    {
      path: "/loginHistory",
      name: "loginHistory",
      component: loginHistory,
    },
  ]
})

const needLogin = ['adminMain', 'admorList', 'ecgDeviceList', 'ecgTestList', 'loginHistory', 'userList'];

router.beforeEach((to, from, next) => {
  const admorLoggedIn = store.state.admorLoggedIn;
  console.log("router.beforeEach:", admorLoggedIn, to, from, needLogin);

  if((admorLoggedIn == null || admorLoggedIn.isLoggedIn != true) && needLogin.includes(to.name)) {
    console.log("needLogin");
    next({name: 'login'});
  } else {
    console.log("next:", to);
    next();
  }
})

export default router;