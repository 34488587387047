<template>
  <div style="text-align:center;" class="mt-10">
    <div class="ma-2">
      influxDB test
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="testInsertClicked"
        >influxDB insert test
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="testSelectClicked"
        >influxDB select test
      </v-btn>
    </div>
    <div class="ma-2">
      app test
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="insertUserClicked"
        >insert user
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="updateUserClicked"
        >update user
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="updateSettClicked"
        >update setting
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="insertTestlicked"
        >insert test
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="sendSmsAuthNoClicked"
        >send SMS Auth
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="checkSmsAuthNoClicked"
        >check SMS Auth
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="pushTestClicked"
        >PUSH Test
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="app_ecgTestListClicked"
        >Ecg Test List
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="app_ecgTestDetailClicked"
        >Ecg Test Detail
      </v-btn>

      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="sendSmsEmergMsg"
        >sendSmsEmergMsg
      </v-btn>

      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="deleteEcgTest"
        >deleteEcgTest
      </v-btn>
      
    </div>
    <div class="ma-2">
      admin test
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="loginClicked"
        >login
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="userListClicked"
        >User List
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="ecgDvcListClicked"
        >Ecg Device List
      </v-btn>
      <v-btn 
        small 
        color="blue lighten-2" 
        class="white--text ml-3"
        @click="admin_ecgTestListClicked"
        >admin - Ecg Test List
      </v-btn>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    // influxDB 테스트
    testInsertClicked() {
      const params = new FormData();

      // params.append()

      this.$http.post("/api/mobile/influxInsertTest", params).then((res) => {
        // alert(res.data.result);
        console.log("testInsertClicked > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    testSelectClicked() {
      const params = new FormData();

      // params.append()

      this.$http.post("/api/mobile/influxSelectTest", params).then((res) => {
        // alert(res.data.result);
        console.log("influxSelectTest > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    // 모바일 앱 테스트
    insertUserClicked() {
      const reqParam = {
        "user_nm": "홍길동",
        "user_cel_no": "010-1111-2222",
        "push_token": "qwerasdfzxcv",
        "ecg_dvc_id": "ASDFQWER1234",
        "ecg_dvc_nm": "ECG_DEVICE",
        "emgnc_cntt1_nm": "긴급1",
        "emgnc_cntt1_tel_no": "010-0000-0000",
        "emgnc_cntt2_nm": "긴급2",
        "emgnc_cntt2_tel_no": "010-1111-1111",
        "emgnc_cntt3_nm": "긴급3",
        "emgnc_cntt3_tel_no": "010-2222-2222",
      };

      this.$http.post("/api/mobile/insertUser", JSON.stringify(reqParam),
        {headers: {'Content-Type': 'application/json'}})
      .then((res) => {
        // alert(res.data.result);
        console.log("insertUser > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    updateUserClicked() {
      // const reqParam = {
      //   "user_nm": "홍길동",
      //   "user_cel_no": "010-1111-2222",
      //   "new_user_nm": "김길동",
      //   "new_user_cel_no": "010-1111-3333",
      //   "ecg_dvc_id": "ASDFQWER1234",
      //   "ecg_dvc_nm": "ECG_DEVICE",
      //   "emgnc_cntt1_nm": "긴급1",
      //   "emgnc_cntt1_tel_no": "010-0000-0000",
      //   "emgnc_cntt2_nm": "긴급2",
      //   "emgnc_cntt2_tel_no": "010-1111-1111",
      //   "emgnc_cntt3_nm": "긴급3",
      //   "emgnc_cntt3_tel_no": "010-2222-2222",
      // };
      const reqParam = {
          "user_nm": "bgwell",
          "user_cel_no": "010-7422-2046",
          "new_user_nm": "bgwell",
          "new_user_cel_no": "010-7422-2047",
          "ecg_dvc_id": "f47ac10b-58cc-4372-a567-0e02b2c3d479",
          "ecg_dvc_nm": "ECG_TEST",
          "emgnc_cntt1_nm": "bgwell",
          "emgnc_cntt1_tel_no": "010-7422-2046",
          "emgnc_cntt2_nm": "",
          "emgnc_cntt2_tel_no": "",
          "emgnc_cntt3_nm": "",
          "emgnc_cntt3_tel_no": ""
      };

      this.$http.post("/api/mobile/updateUser", JSON.stringify(reqParam),
        {headers: {'Content-Type': 'application/json'}})
      .then((res) => {
        // alert(res.data.result);
        console.log("updateUser > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    updateSettClicked() {
      const reqParam = {
        "user_nm": "김길동",
        "user_cel_no": "010-1111-3333",
        "push_recv_yn": "N",
        "lang": "en",
      };

      this.$http.post("/api/mobile/updateSett", JSON.stringify(reqParam),
        {headers: {'Content-Type': 'application/json'}})
      .then((res) => {
        // alert(res.data.result);
        console.log("updateSett > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    insertTestlicked() {
      // const params = new FormData();

      // params.append("user_nm", "홍길동");
      // params.append("user_cel_no", "010-1111-3333");
      // params.append("test_patch_kind", "6LEAD");
      // params.append("test_bgn_dtm", "20230621120000");
      // params.append("test_end_dtm", "20230621120500");
      // params.append("test_res", "OK");

      
      const reqParam = {
        "user_nm": "홍길동",
        "user_cel_no": "010-1111-3333",
        "test_patch_kind": "6LEAD",
        "test_bgn_dtm": "20230621120000",
        "test_end_dtm": "20230621120500",
        "test_res": "OK",
        "test_uuid": "ttt"
      };
      console.log(JSON.stringify(reqParam));
      // params.append("reqParam", JSON.stringify(reqParam));
      

      // this.$http.post("/api/mobile/insertEcgTest", params,
      //   {headers: {'Content-Type': 'multipart/form-data'}})
      // .then((res) => {
      //   // alert(res.data.result);
      //   console.log("insertEcgTest > res.data:", res.data);
      // })
      // .catch(function (error) {
      //   console.log(error);
      //   }
      // );
    },
    sendSmsAuthNoClicked () {
      // const params = new FormData();

      const reqParam = {
        "user_cel_no": "010-8959-1806",
        "api_hash": '123123'
      };

      // this.$http.post("/api/mobile/sendSmsTest", JSON.stringify(reqParam),
      this.$http.post("/api/mobile/sendSmsAuthNo", JSON.stringify(reqParam),
        {headers: {'Content-Type':'application/json'}}).then((res) => {
        // alert(res.data.result);
        console.log("sendSmsTest > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    checkSmsAuthNoClicked () {
      // const params = new FormData();

      const reqParam = {
        "user_cel_no": "010-8959-1806",
        "auth_no": "322033"
      };

      // this.$http.post("/api/mobile/sendSmsTest", JSON.stringify(reqParam),
      this.$http.post("/api/mobile/checkSmsAuthNo", JSON.stringify(reqParam),
        {headers: {'Content-Type':'application/json'}}).then((res) => {
        // alert(res.data.result);
        console.log("sendSmsTest > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    pushTestClicked () {
      // const params = new FormData();

      const reqParam = {

      };

      this.$http.post("/api/mobile/sendPushTest", JSON.stringify(reqParam),
        {headers: {'Content-Type':'application/json'}}).then((res) => {
        // alert(res.data.result);
        console.log("sendPushTest > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    // 관리자 웹 테스트
    loginClicked() {
      const params = new FormData();

      params.append("login_id", "admor");
      params.append("pwd", "1234");

      this.$http.post("/api/admin/login", params).then((res) => {
        // alert(res.data.result);
        console.log("login > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    userListClicked() {
      const params = new FormData();

      this.$http.post("/api/admin/getUserList", params).then((res) => {
        // alert(res.data.result);
        console.log("getUserList > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    ecgDvcListClicked () {
      const params = new FormData();

      this.$http.post("/api/admin/getEcgDvcList", params).then((res) => {
        // alert(res.data.result);
        console.log("getEcgDvcList > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    admin_ecgTestListClicked () {
      const params = new FormData();

      params.append("user_nm", "홍길동");
      params.append("user_cel_no", "010-1111-3333");

      this.$http.post("/api/admin/getEcgTestList", params).then((res) => {
        // alert(res.data.result);
        console.log("getEcgTestList > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    app_ecgTestListClicked() {
      const params = new FormData();

      params.append("user_nm", "홍길동");
      params.append("user_cel_no", "010-1111-3333");

      const reqParam = {
        "user_nm": "홍길동",
        "user_cel_no": "010-1111-3333"
      };
      

      this.$http.post("/api/mobile/getEcgTestList", JSON.stringify(reqParam),
        {headers: {'Content-Type': 'application/json'}}).then((res) => {
        // alert(res.data.result);
        console.log("getEcgTestList > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
    app_ecgTestDetailClicked() {

      const reqParam = {
        "user_nm": "홍길동",
        "user_cel_no": "010-1111-3333",
        "test_uuid": "ttt",
      };

      this.$http.post("/api/mobile/getEcgTest", JSON.stringify(reqParam),
        {headers: {'Content-Type': 'application/json'}}).then((res) => {
        // alert(res.data.result);
        console.log("getEcgTest > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },//app_ecgTestDetailClicked end
    sendSmsEmergMsg() {
      const reqParam = {
        "user_cel_no": "01089591806",
        "emerg_type": "fall",
        "user_nm": "전지훈"
      };

      this.$http.post("/api/mobile/sendSmsEmergMsg", JSON.stringify(reqParam)).then((res) => {
        // alert(res.data.result);
        console.log("getEcgTest > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },//sendSmsEmergMsg end
    deleteEcgTest() {
      const reqParam = {
        "user_cel_no": "111",
        "user_nm": "11",
        "hist_sno" : "1"
      };

      this.$http.post("/api/mobile/deleteEcgTest", JSON.stringify(reqParam)).then((res) => {
        console.log("getEcgTest > res.data:", res.data);
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },//deleteEcgTest end
  }
}
</script>