<template>
  <v-container fluid>
    <div>
      <v-app-bar app color="primary" class="white--text">
        <v-row align="center">
          <v-col cols="1">
            <v-btn 
              icon  
              @click="$router.go(-1)">
              <v-icon class="white--text">mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10" class="justify-center d-flex">
            <v-btn 
              @click="$router.go(-1)" 
              class="white--text"
              text>
              럭스나인 바디로그
            </v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-app-bar>
      <div class="mt-5 pa-5 d-flex justify-center align-center m-adpList-pd4">
        <v-row>
          <v-col cols="12" class="d-flex justify-center align-center">
            <div> 
              <img style="width: 300px;" src="">
            </div>
          </v-col>
          <v-col cols="12" >
            <div style="overflow:auto; text-align: left;" class="white">
              <h2>개인정보 수집 및 이용</h2>
              <br>
              <h3>수집하는 개인정보 항목</h3>
              <br>
              회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br>
              <br>
              ο 수집항목 : 이름 , 생년월일 , 성별 , 로그인ID , 비밀번호 , 비밀번호 질문과 답변 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 ,  서비스 이용기록 , 접속 로그 , 접속 IP 정보 , 결제기록<br>
              ο 개인정보 수집방법 : 홈페이지(회원가입) , 서면양식<br>
              <br>
              <h3>개인정보의 수집 및 이용목적</h3>
              <br>
              회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br>
              <br>
              ο 서비스 제공에 관한 계약 이행 및 서비스 제공<br>
              ο 회원 관리<br>
              <br>
              <h3>개인정보의 보유 및 이용기간</h3>
              <br>
              회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다. 다만 제품에 대한 보증기간이 15년이므로 해당자에 대해서 개인정보 보유기간을 보증기간과 같이 합니다.<br>
              <br>
              <div>
                <span>
                  06664 서울특별시 서초구 방배로 132 (방배동) 장산빌딩 5층<br>
                  럭스나인<br>
                  1644-1598<br>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  created(){
    console.log("privacyPolicy created.");
  },
  mounted() {
    console.log("privacyPolicy mounted.");
  },
  methods: {

  }
}
</script>

<style scoped>

</style>