import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const genInitialState = () => {
  const saveLoginId = localStorage.getItem("saveLoginId") == null ? {isSaveId: false, loginId: null} : JSON.parse(localStorage.getItem("saveLoginId"));
  const admorLoggedIn = localStorage.getItem("saveLoginId") == null ? {isLoggedIn: false, admorSno: null, permLvl: null} : JSON.parse(localStorage.getItem("admorLoggedIn"));

  return {
    saveLoginId,
    admorLoggedIn,
  };
}

export default new Vuex.Store({
  state: {
    // num: 0,
    saveLoginId: {
      isSaveId: false,
      loginId: null,
    },
    admorLoggedIn: {
      isLoggedIn: false,
      admorSno: null,
      permLvl: null,
    },
    ...genInitialState(),
  },
  mutations: {
    // changeNumber (state, val) {
    //   state.num += val
    // }
    setSaveLoginId(state, payload) {
      console.log("setSaveLoginId payload:", payload);

      state.saveLoginId.isSaveId = payload.isSaveId;
      state.saveLoginId.loginId = payload.loginId;

      localStorage.setItem("saveLoginId", JSON.stringify(state.saveLoginId));
    },
    setAdmorLoggedIn(state, payload) {
      console.log("setAdmorLoggedIn payload:", payload);

      if(payload != null) {
        state.admorLoggedIn.isLoggedIn = payload.isLoggedIn;
        state.admorLoggedIn.admorSno = payload.admorSno;
        state.admorLoggedIn.permLvl = payload.permLvl;
      } else {
        state.admorLoggedIn.isLoggedIn = false;
        state.admorLoggedIn.admorSno = null;
        state.admorLoggedIn.permLvl = null;
      }

      localStorage.setItem("admorLoggedIn", JSON.stringify(state.admorLoggedIn));
    },
  },
  actions: {
    // callMutation ({ state, commit }, { num }) {
    //   commit('changeNumber', num)
    // }
  },
  getters: {
    // getMsg (state) {
    //   return `calculated => ${state.num}`
    // }
    getAdmorIsLoggedIn(state) {
      return state.admorLoggedIn.isLoggedIn;
    },
    getAdmorPermLvl(state) {
      return state.admorLoggedIn.permLvl;
    },
    getAdmorSno(state) {
      return state.admorLoggedIn.admorSno;
    }
  }
})