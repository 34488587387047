<template>
  <v-container class="pa-3">
    <v-row dense class="">
      <v-col cols="12" class="">
        <v-card flat outlined height="85vh" class="pa-3">
          <v-row dense>
            <v-col cols="12">
              <div class="pt-1">
                <h4>
                  사용자 정보
                </h4>
              </div>
            </v-col>
            <v-col cols="12" class="">
              <v-row dense align="center">
                <v-col cols="3" class="d-flex align-center">
                  <v-text-field 
                    outlined 
                    hide-details
                    dense 
                    height="25"
                    class="mr-2"
                    v-model="search"
                    placeholder="검색어를 입력하세요."
                    :append-icon="search != null && search != '' ? 'mdi-close-circle' : ''"
                    @click:append="clearSearch"
                    >
                  </v-text-field>
                  <!-- <v-btn color="indigo lighten-1" class="white--text">
                    조회
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="gridHeaders"
                :items="gridList"
                :search="search"
                hide-default-footer
                :items-per-page="itemsPerPage"
                :page.sync="page"
                @page-count="pageCount = $event"
                :loading="loading"
                loading-text="Loading... Please wait"
                >
                <template v-slot:[`item.no`]="{index}" >
                  {{ (itemsPerPage * (page - 1)) + index + 1}}
                </template>
                <template v-slot:no-results>
                  <span>검색 결과가 없습니다.</span>
                </template>
              </v-data-table>
              <div class="text-center pt-2 hidden-md-and-down">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data(){
    return{
      gridHeaders: [
        { text: 'No.', value:'no'},
        { text: '사용자 이름', value:'user_nm'},
        { text: '사용자 전화번호', value:'user_cel_no'},
        { text: '긴급 연락처', value:'emgnc_cntt1_tel_no'},
        { text: '사용 심전도 장치', value:'ecg_dvc_nm'},
        { text: '등록일시', value:'reg_dtm'},
      ],
      gridList: [],
      search: null,

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      loading: false,
    }
  },
  created() {

  },
  mounted() {
    this.getUserList();
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
    getUserList() {
      const params = new FormData();

      this.$http.post("/api/admin/getUserList", params).then((res) => {
        // alert(res.data.result);
        console.log("getUserList > res.data:", res.data);
        this.gridList = res.data.userList;
      })
      .catch(function (error) {
        console.log(error);
        }
      );
    },
  }
}
</script>

<style>

</style>